/** ***************************************************************************
 * Ecommerce / Templates / Base
 *************************************************************************** */

window.addEventListener('conchiglia:init', () => {
  function setupEventListeners() {}

  function initialize() {
    const elementAfterHeader = document.querySelector('.cn-c-header + *');

    if (elementAfterHeader) {
      if (elementAfterHeader.classList.contains('cn-c-notifications')) {
        const elementAfterNotifications = document.querySelector(
          '.cn-c-notifications + *'
        );
        if (elementAfterNotifications) {
          setMarginClass(elementAfterNotifications);
        }
      } else {
        setMarginClass(elementAfterHeader);
      }
    }
  }

  function setMarginClass(element) {
    element.classList.add('margin-to-set');
  }

  function destroy() {}

  window.CONCHIGLIA.register('ecommerce-templates-base', initialize, destroy);

  initialize();
});
